import React, { useEffect, useState, Fragment } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/layout"
import Style from "../components/covid/covid.style"
import { dropdownStyle } from "../components/explore/dropdown.style"
import { flow, map, reverse, sortBy } from "lodash/fp"
import dayjs from "dayjs"
import Select from "react-select"
import SEO from "../components/common/seo"
import { Media } from "../Media"

type CoronavirusItem = {
  node: {
    title: string
    date: string
    category?: string
    content: {
      childMarkdownRemark: {
        html: string
      }
    }
  }
}

interface Props {
  data: {
    contentfulCoronavirusPage: {
      menuTitle: string
      titleSeo: string
      publish: boolean
      description?: string
      pageSubtitle: {
        pageSubtitle: string
      }
      closed: {
        name: string
        slug: string
      }[]
      pageTitle: string
      restaurantsAndBarsMessage: {
        restaurantsAndBarsMessage: string
      }
      changedHours: {
        name: string
        slug: string
      }[]
    }
    allContentfulCoronavirusItem: {
      edges: CoronavirusItem[]
    }
  }
}

// https://stackoverflow.com/a/3426956
function hashCode(str: string) {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  return hash
}

function intToRGBA(i: number) {
  const c = (i & 0x00ffffff).toString(16).toUpperCase()

  return "#00000".substring(0, 7 - c.length) + c + "80"
}

const AtHome = ({
  data: {
    contentfulCoronavirusPage: content,
    allContentfulCoronavirusItem: items,
  },
}: Props) => {
  const categories = [
    "All",
    ...new Set(items.edges.map(item => item.node.category?.toLowerCase())),
  ]
  const categoriesObjects = [
    ...new Set(
      categories.map(cat => {
        return { value: cat, label: cat }
      })
    ),
  ]
  const [updates, setUpdates] = useState(items.edges)
  const [filter, setFilter] = useState<string | undefined>("All")
  useEffect(() => {
    if (filter === "All") {
      setUpdates(items.edges)
    } else {
      const filteredItems = items.edges.filter(item => {
        return item.node.category && item.node.category.toLowerCase() === filter
      })
      setUpdates(filteredItems)
    }
  }, [filter, setUpdates])

  return (
    <Layout>
      <SEO title={content.titleSeo} description={content.description} />
      <Style.Background>
        <Style.Container>
          <Style.Title>{content.pageTitle}</Style.Title>
          <Style.Subtitle>{content.pageSubtitle.pageSubtitle}</Style.Subtitle>
          <Style.SectionTitle>Ongoing Updates</Style.SectionTitle>
          <Style.CategoriesBar>
            <Media greaterThanOrEqual="md">
              {categoriesObjects.map((element, index) => (
                <Style.CategoryTab
                  onClick={() => setFilter(element.value)}
                  active={element.value === filter}
                  key={index}
                >
                  <Style.CategoryText>{element.label}</Style.CategoryText>
                </Style.CategoryTab>
              ))}
            </Media>

            <Media lessThan="md">
              <Select
                placeholder={"Filter updates"}
                isSearchable={false}
                options={categoriesObjects}
                value={{ value: filter, label: filter }}
                onChange={event => setFilter(event.value)}
                styles={{
                  valueContainer: () => {
                    return { width: 200 }
                  },
                  ...dropdownStyle,
                }}
              />
            </Media>
          </Style.CategoriesBar>
          <Style.UpdatesContainer>
            {flow(
              map(({ node }) => node),
              sortBy("date"),
              reverse,
              map((item: CoronavirusItem["node"]) => (
                <Fragment key={`${item.title}-${item.date}`}>
                  <Style.UpdateTopRow>
                    <Style.UpdateDate>
                      {dayjs(item.date).format("MMMM D, YYYY")}
                    </Style.UpdateDate>
                    {item.category && (
                      <Style.UpdateCategory
                        backgroundColor={intToRGBA(hashCode(item.category))}
                      >
                        {item.category}
                      </Style.UpdateCategory>
                    )}
                  </Style.UpdateTopRow>
                  <Style.UpdateTitle>{item.title}</Style.UpdateTitle>
                  <Style.UpdateText
                    dangerouslySetInnerHTML={{
                      __html: item.content.childMarkdownRemark.html,
                    }}
                  />
                </Fragment>
              ))
            )(updates)}
          </Style.UpdatesContainer>
        </Style.Container>
      </Style.Background>
    </Layout>
  )
}

export default AtHome

export const query = graphql`
  query CovidPageQuery {
    contentfulCoronavirusPage(titleSeo: { ne: "dummy" }) {
      menuTitle
      titleSeo
      publish
      title
      description
      pageSubtitle {
        pageSubtitle
      }
      closed {
        name
        slug
      }
      pageTitle
      restaurantsAndBarsMessage {
        restaurantsAndBarsMessage
      }
      changedHours {
        name
        slug
      }
    }
    allContentfulCoronavirusItem {
      edges {
        node {
          title
          date
          category
          content {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
