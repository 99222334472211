import styled from "styled-components"
import { COLORS, FONTS } from "../../styles"
import React from "react"
import CustomLink from "../link"

//TODO: This is not on the website atm.
const BaseText = styled.div`
  color: ${COLORS.HOLLY_GREEN};
  font-family: ${FONTS.SOHNE_FONT_FAMILY};
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.22;
`

const BaseSubTitle = styled.div`
  font-family: ${FONTS.SOHNE_FONT_FAMILY};
  font-weight: 900;
  font-size: 0.83rem;
  letter-spacing: 0.044rem;
  text-transform: uppercase;
  line-height: 1.88;
  color: ${COLORS.HOLLY_GREEN};
`

const BaseTitle = styled.div`
  color: ${COLORS.HOLLY_GREEN};
  font-family: ${FONTS.TITLE_FONT_FAMILY};
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.44;
`

export default {
  Background: styled.div``,
  Container: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    align-self: stretch;
    padding-top: 3rem;
    padding-bottom: 3rem;
  `,
  Title: styled(BaseTitle)`
    margin-bottom: 0.63rem;
    text-align: center;
  `,
  Subtitle: styled(BaseText)`
    text-align: center;
    max-width: 600px;
    margin-bottom: 80px;
  `,
  StoreName: styled(({ children, to, className }) => (
    <CustomLink isInternal link={to} className={className}>
      <BaseText className={className}>{children}</BaseText>
    </CustomLink>
  ))`
    line-height: 1.67;
    text-decoration: none;
    :hover {
      text-decoration: underline;
    }
  `,
  RestaurantsAndBarsText: BaseText,
  StoreInfoContainer: styled.div`
    display: flex;
    flex-direction: row;
    align-self: center;
    margin-bottom: 80px;
    padding-left: 3rem;
    padding-right: 3rem;
    max-width: 1100px;
  `,
  StoreInfoColumn: styled.div`
    flex: 1;
  `,
  TitleText: styled(BaseSubTitle)`
    margin-bottom: 25px;
  `,
  SectionTitle: styled(BaseSubTitle)`
    background-color: ${COLORS.AQUA_HAZE};
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    align-self: stretch;
  `,
  CategoriesBar: styled(BaseSubTitle)`
    background-color: ${COLORS.WHITE};
    border-bottom: 0.75px solid;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    align-self: stretch;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  `,
  UpdatesContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    max-width: 1000px;
    padding-left: 20px;
    padding-right: 20px;
  `,
  UpdateTopRow: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-self: stretch;
    margin-bottom: 0.78rem;
    margin-top: 60px;
  `,
  UpdateDate: styled(BaseSubTitle)`
    font-size: 0.78rem;
    line-height: 2.14;
  `,
  UpdateCategory: styled(BaseSubTitle)<{ backgroundColor: string }>`
    background-color: ${props => props.backgroundColor};
    font-size: 0.78rem;
    line-height: 2.14;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  `,
  UpdateTitle: styled(BaseTitle)`
    margin-bottom: 1.5rem;
  `,
  UpdateText: styled(BaseText)`
    padding-bottom: 60px;
    border-bottom: 1px solid ${COLORS.HOLLY_GREEN};
    :last-child {
      border-bottom: none;
    }
  `,
  CategoryText: styled.span`
    font-family: ${FONTS.SOHNE_FONT_FAMILY};
    font-size: 15px;
    line-height: 1.07;
    letter-spacing: 0.15px;
    font-weight: 500;
  `,
  CategoryTab: styled.div<{ active: boolean }>`
    cursor: pointer;
    padding: 4px 10px;
    color: ${COLORS.BOTTLE_GREEN};
    &:hover {
      background-color: ${COLORS.BOTTLE_GREEN};
      color: ${COLORS.WHITE};
    }
    ${({ active }) =>
      active &&
      `
      background-color: ${COLORS.BOTTLE_GREEN};
      color: ${COLORS.WHITE};
 `}
  `,
}
